/* 전역설정 */
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import 'vue-multiselect/dist/vue-multiselect.min.css';
@import '../fonts/pretendard.css';
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&display=swap');
@import url('https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@2.0/nanumsquare.css');

// pretendard 폰트 불러오기
//@import url("https://unpkg.com/pretendard@1.3.9/dist/web/static/pretendard.css");


:root {
  --theme-selected-color: #F37324;
  --theme-primary-color: #F37324;
  --theme-secondary-color: #36B6B0;
  --theme-tertiary-color: #2956db;

  --white: #fff;
  --Primary: #F37324;


  --Primary: #F37324;
  // --theme-primary-color: crimson;
  --gray-10: #E9E9E9;
  --gray-20: #E1E1E1;
  --gray-30: #bcbcbc;
  --gray-40: #A4A4A4;
  --gray-50: #8F8F8F;
  --gray-60: #787878;
  --gray-70: #626262;
  --gray-80: #4B4B4B;
  --gray-90: #353535;
  --gray-100: #1E1E1E;


  --yellow-10: #F8F7EF;
  --yellow-20: #ECEBE3;
  --yellow-30: #E8E6D6;

  --disable-font: #A4A4A4;
  --disable-bg: #E3E3E3;
}

.toggle-nav {
  cursor: pointer;
  background: none;
  z-index: 999;
  border-radius: 10px;
  padding: 0;
  margin: 32px 0 0 32px;
  position: sticky;
  height: 32px;
  width: 32px;
  border: 0;

  .icon {
    color: #aaa;
    background-color: transparent;
    font-size: 30px;
  }
}

html, body {
  font-family: "Pretendard", Pretendard;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #333;
}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button{margin:0;padding:0;font-family: "Pretendard", Pretendard;}
select,input,textarea{
  // display: none;
  font-family:"Pretendard",Pretendard !important;
}
.loading {
  // animation: loading 1s infinite;
  opacity: 0.3;
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  // height : 2200px;
  // -webkit-transform: translateY(100%);
  // transform: translateY(100%);
  z-index: 1000;
  position: fixed;
}

.fullscreen {
  width: 100%;
}

.normal {
  display: flex;
  min-height: 100%;
  align-content: stretch;
  width: 100%;
  background:#F8F7EF;
}

.wrap .main label {
  height: 34px;
  line-height: 34px;
  display: inline-block;
}

.right {
  float: right !important;
}

.container {
  // overflow: auto;
  // width:100%;
  // background-color: red;
  // padding: 0 72px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;

  a {
    color: var(--theme-primary-color);
    font-weight: 600;
  }

  a:hover {
    text-decoration: underline;
  }

  .counter {
    display: flex;
    align-items: flex-end;
    // padding: 16px 0;
    // margin: 0;
    &.mt16{
      margin-top:16px;
    }
    .select-box {
      display: flex;
      margin-left: auto;
      gap: 10px;
    }
  }


  .filter,
  .counter {
    margin: 30px 0 16px 0;
    // background-color: red;
    box-sizing: border-box;
    // padding: 0 4px;
    height: fit-content;
    line-height: normal;
    flex-basis: 0;
    flex-shrink: 0;
    border-bottom: 1px solid #ddd;

    .form-block {
      display: block;
      max-width: 100%;
      margin: 0;
    }

    .form-inline {
      display: inline-block;
      max-width: 100%;
      margin: 0;
    }

    .form-flex-row {
      display: flex;
      flex-direction: row;
    }

    label {
      line-height: 40px;
      box-sizing: border-box;
      margin: 0;
      min-width: 120px;
      padding: 0 10px;
      font-weight: 600;
      vertical-align: top;

      sup {
        line-height: normal;
      }
    }

    .inputbox + label {
      vertical-align: baseline;
      display: inline-block !important;
    }

    .inputbox > label {
      min-width: auto;
    }

  }

  .filter {
    margin: 0;
    border-bottom: none;
  }

  .counter {
    border-bottom: none;
  }

  // >.content {
  //   overflow: auto;
  //   background:
  //     linear-gradient(to right, #ffffff 33%, rgba(255,255,255, 0)),
  //     linear-gradient(to right, rgba(255,255,255, 0), #ffffff 66%),
  //     radial-gradient(farthest-side at left,  rgba(54,54,54, 0.2), rgba(0,0,0,0)),
  //     radial-gradient(farthest-side at right, rgba(54,54,54, 0.2), rgba(0,0,0,0));
  //   background-position: left center, right center, left center, right center;
  //   background-repeat: no-repeat;
  //   background-size: 100px 100%, 100px 100%, 40px 100%, 40px 100%;
  //   background-attachment: local, local, scroll, scroll;
  // }
}

.horizontal-scroll-shadows {
  background: linear-gradient(to right, #ffffff 33%, rgba(255, 255, 255, 0)),
  linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff 66%),
  radial-gradient(farthest-side at left, rgba(54, 54, 54, 0.2), rgba(0, 0, 0, 0)),
  radial-gradient(farthest-side at right, rgba(54, 54, 54, 0.2), rgba(0, 0, 0, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-size: 100px 100%, 100px 100%, 40px 100%, 40px 100%;
  background-attachment: local, local, scroll, scroll;
}

.no-data {
  display: flex;
  flex-direction: column;
  padding: 56px 0;
  line-height: normal;
  border-bottom: 1px solid #e0e0db;

  strong {
    color: #000;
    font-size: 15px;
    font-weight: 400;
  }

  span {
    margin-top: 6px;
    color: #8F8F8F;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
  }

  div {
    flex: 1;
    text-align: center
  }
}

.monospaced {
  font-family: 'Nanum Gothic Coding';
  font-size: 1.1em;
}

.code {
  background: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  overflow: auto;
}

.panel {
  &.visible {
    overflow: visible;
  }

  overflow: auto;
  min-width: 100%;
  max-width: fit-content;
  background: #fcfcfc77;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 5px;
  margin-top: 10px;
  box-sizing: border-box;

  .icon {
    vertical-align: -.14em;
  }

  h4.title {
    padding: 0;
    margin: 10px 2px;
  }

  ul {
    margin: 10px 0;
    padding-inline-start: 20px;

    li:not(:last-child) {
      margin-bottom: 3px;
    }

    li::marker {
      color: #111;
      font-size: 1px;
      list-style-type: circle;
    }
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  th {
    font-weight: 600;
    text-align: left;
    white-space: nowrap;

    span {
      color: red
    }
  }
}

.submit-bar {
  margin: 20px auto 40px auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 0px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.file-label {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 32px !important;
  text-indent: 8px;
  width: 100%;
  height: auto;
  font-family: inherit;
  padding: 0 2px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 4px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  input {
    + span {
      cursor: pointer;
    }

    &:disabled + span {
      color: #808080;
      cursor: not-allowed;
    }
  }
}

//텍스트 숨기기용
.blind {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

@keyframes loading {
  0% {
    backdrop-filter: blur(0px);
  }
  50% {
    backdrop-filter: blur(3px);
  }
  100% {
    backdrop-filter: blur(0px);
  }
}


// 빙글빙글~
.rotate-bg {
  animation: rotate 5s infinite;
}

@keyframes rotate {
  0% {
    background: linear-gradient(0deg, #45cba5, #27a39b);
  }
  1% {
    background: linear-gradient(3.6deg, #45cba5, #27a39b);
  }
  2% {
    background: linear-gradient(7.2deg, #45cba5, #27a39b);
  }
  3% {
    background: linear-gradient(10.8deg, #45cba5, #27a39b);
  }
  4% {
    background: linear-gradient(14.4deg, #45cba5, #27a39b);
  }
  5% {
    background: linear-gradient(18deg, #45cba5, #27a39b);
  }
  6% {
    background: linear-gradient(21.6deg, #45cba5, #27a39b);
  }
  7% {
    background: linear-gradient(25.2deg, #45cba5, #27a39b);
  }
  8% {
    background: linear-gradient(28.8deg, #45cba5, #27a39b);
  }
  9% {
    background: linear-gradient(32.4deg, #45cba5, #27a39b);
  }
  10% {
    background: linear-gradient(36deg, #45cba5, #27a39b);
  }
  11% {
    background: linear-gradient(39.6deg, #45cba5, #27a39b);
  }
  12% {
    background: linear-gradient(43.2deg, #45cba5, #27a39b);
  }
  13% {
    background: linear-gradient(46.800000000000004deg, #45cba5, #27a39b);
  }
  14% {
    background: linear-gradient(50.4deg, #45cba5, #27a39b);
  }
  15% {
    background: linear-gradient(54deg, #45cba5, #27a39b);
  }
  16% {
    background: linear-gradient(57.6deg, #45cba5, #27a39b);
  }
  17% {
    background: linear-gradient(61.2deg, #45cba5, #27a39b);
  }
  18% {
    background: linear-gradient(64.8deg, #45cba5, #27a39b);
  }
  19% {
    background: linear-gradient(68.4deg, #45cba5, #27a39b);
  }
  20% {
    background: linear-gradient(72deg, #45cba5, #27a39b);
  }
  21% {
    background: linear-gradient(75.60000000000001deg, #45cba5, #27a39b);
  }
  22% {
    background: linear-gradient(79.2deg, #45cba5, #27a39b);
  }
  23% {
    background: linear-gradient(82.8deg, #45cba5, #27a39b);
  }
  24% {
    background: linear-gradient(86.4deg, #45cba5, #27a39b);
  }
  25% {
    background: linear-gradient(90deg, #45cba5, #27a39b);
  }
  26% {
    background: linear-gradient(93.60000000000001deg, #45cba5, #27a39b);
  }
  27% {
    background: linear-gradient(97.2deg, #45cba5, #27a39b);
  }
  28% {
    background: linear-gradient(100.8deg, #45cba5, #27a39b);
  }
  29% {
    background: linear-gradient(104.4deg, #45cba5, #27a39b);
  }
  30% {
    background: linear-gradient(108deg, #45cba5, #27a39b);
  }
  31% {
    background: linear-gradient(111.60000000000001deg, #45cba5, #27a39b);
  }
  32% {
    background: linear-gradient(115.2deg, #45cba5, #27a39b);
  }
  33% {
    background: linear-gradient(118.8deg, #45cba5, #27a39b);
  }
  34% {
    background: linear-gradient(122.4deg, #45cba5, #27a39b);
  }
  35% {
    background: linear-gradient(126deg, #45cba5, #27a39b);
  }
  36% {
    background: linear-gradient(129.6deg, #45cba5, #27a39b);
  }
  37% {
    background: linear-gradient(133.20000000000002deg, #45cba5, #27a39b);
  }
  38% {
    background: linear-gradient(136.8deg, #45cba5, #27a39b);
  }
  39% {
    background: linear-gradient(140.4deg, #45cba5, #27a39b);
  }
  40% {
    background: linear-gradient(144deg, #45cba5, #27a39b);
  }
  41% {
    background: linear-gradient(147.6deg, #45cba5, #27a39b);
  }
  42% {
    background: linear-gradient(151.20000000000002deg, #45cba5, #27a39b);
  }
  43% {
    background: linear-gradient(154.8deg, #45cba5, #27a39b);
  }
  44% {
    background: linear-gradient(158.4deg, #45cba5, #27a39b);
  }
  45% {
    background: linear-gradient(162deg, #45cba5, #27a39b);
  }
  46% {
    background: linear-gradient(165.6deg, #45cba5, #27a39b);
  }
  47% {
    background: linear-gradient(169.20000000000002deg, #45cba5, #27a39b);
  }
  48% {
    background: linear-gradient(172.8deg, #45cba5, #27a39b);
  }
  49% {
    background: linear-gradient(176.4deg, #45cba5, #27a39b);
  }
  50% {
    background: linear-gradient(180deg, #45cba5, #27a39b);
  }
  51% {
    background: linear-gradient(183.6deg, #45cba5, #27a39b);
  }
  52% {
    background: linear-gradient(187.20000000000002deg, #45cba5, #27a39b);
  }
  53% {
    background: linear-gradient(190.8deg, #45cba5, #27a39b);
  }
  54% {
    background: linear-gradient(194.4deg, #45cba5, #27a39b);
  }
  55% {
    background: linear-gradient(198deg, #45cba5, #27a39b);
  }
  56% {
    background: linear-gradient(201.6deg, #45cba5, #27a39b);
  }
  57% {
    background: linear-gradient(205.20000000000002deg, #45cba5, #27a39b);
  }
  58% {
    background: linear-gradient(208.8deg, #45cba5, #27a39b);
  }
  59% {
    background: linear-gradient(212.4deg, #45cba5, #27a39b);
  }
  60% {
    background: linear-gradient(216deg, #45cba5, #27a39b);
  }
  61% {
    background: linear-gradient(219.6deg, #45cba5, #27a39b);
  }
  62% {
    background: linear-gradient(223.20000000000002deg, #45cba5, #27a39b);
  }
  63% {
    background: linear-gradient(226.8deg, #45cba5, #27a39b);
  }
  64% {
    background: linear-gradient(230.4deg, #45cba5, #27a39b);
  }
  65% {
    background: linear-gradient(234deg, #45cba5, #27a39b);
  }
  66% {
    background: linear-gradient(237.6deg, #45cba5, #27a39b);
  }
  67% {
    background: linear-gradient(241.20000000000002deg, #45cba5, #27a39b);
  }
  68% {
    background: linear-gradient(244.8deg, #45cba5, #27a39b);
  }
  69% {
    background: linear-gradient(248.4deg, #45cba5, #27a39b);
  }
  70% {
    background: linear-gradient(252deg, #45cba5, #27a39b);
  }
  71% {
    background: linear-gradient(255.6deg, #45cba5, #27a39b);
  }
  72% {
    background: linear-gradient(259.2deg, #45cba5, #27a39b);
  }
  73% {
    background: linear-gradient(262.8deg, #45cba5, #27a39b);
  }
  74% {
    background: linear-gradient(266.40000000000003deg, #45cba5, #27a39b);
  }
  75% {
    background: linear-gradient(270deg, #45cba5, #27a39b);
  }
  76% {
    background: linear-gradient(273.6deg, #45cba5, #27a39b);
  }
  77% {
    background: linear-gradient(277.2deg, #45cba5, #27a39b);
  }
  78% {
    background: linear-gradient(280.8deg, #45cba5, #27a39b);
  }
  79% {
    background: linear-gradient(284.40000000000003deg, #45cba5, #27a39b);
  }
  80% {
    background: linear-gradient(288deg, #45cba5, #27a39b);
  }
  81% {
    background: linear-gradient(291.6deg, #45cba5, #27a39b);
  }
  82% {
    background: linear-gradient(295.2deg, #45cba5, #27a39b);
  }
  83% {
    background: linear-gradient(298.8deg, #45cba5, #27a39b);
  }
  84% {
    background: linear-gradient(302.40000000000003deg, #45cba5, #27a39b);
  }
  85% {
    background: linear-gradient(306deg, #45cba5, #27a39b);
  }
  86% {
    background: linear-gradient(309.6deg, #45cba5, #27a39b);
  }
  87% {
    background: linear-gradient(313.2deg, #45cba5, #27a39b);
  }
  88% {
    background: linear-gradient(316.8deg, #45cba5, #27a39b);
  }
  89% {
    background: linear-gradient(320.40000000000003deg, #45cba5, #27a39b);
  }
  90% {
    background: linear-gradient(324deg, #45cba5, #27a39b);
  }
  91% {
    background: linear-gradient(327.6deg, #45cba5, #27a39b);
  }
  92% {
    background: linear-gradient(331.2deg, #45cba5, #27a39b);
  }
  93% {
    background: linear-gradient(334.8deg, #45cba5, #27a39b);
  }
  94% {
    background: linear-gradient(338.40000000000003deg, #45cba5, #27a39b);
  }
  95% {
    background: linear-gradient(342deg, #45cba5, #27a39b);
  }
  96% {
    background: linear-gradient(345.6deg, #45cba5, #27a39b);
  }
  97% {
    background: linear-gradient(349.2deg, #45cba5, #27a39b);
  }
  98% {
    background: linear-gradient(352.8deg, #45cba5, #27a39b);
  }
  99% {
    background: linear-gradient(356.40000000000003deg, #45cba5, #27a39b);
  }
  100% {
    background: linear-gradient(0deg, #45cba5, #27a39b);
  }
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}


.mt-8 {
  margin-top: 8px
}

.mt-9 {
  margin-top: 9px
}

.mt-10 {
  margin-top: 10px
}

.mt-11 {
  margin-top: 11px
}

.mt-12 {
  margin-top: 12px
}

.mt-13 {
  margin-top: 13px
}

.mt-14 {
  margin-top: 14px
}

.mt-15 {
  margin-top: 15px
}

.mt-16 {
  margin-top: 16px
}

.mt-17 {
  margin-top: 17px
}

.mt-18 {
  margin-top: 18px
}

.mt-19 {
  margin-top: 19px
}

.mt-20 {
  margin-top: 20px
}

.mt-21 {
  margin-top: 21px
}

.mt-22 {
  margin-top: 22px
}

.mt-23 {
  margin-top: 23px
}

.mt-24 {
  margin-top: 24px
}

.mt-25 {
  margin-top: 25px
}

.mt-26 {
  margin-top: 26px
}

.mt-27 {
  margin-top: 27px
}

.mt-28 {
  margin-top: 28px
}

.mt-29 {
  margin-top: 29px
}

.mt-30 {
  margin-top: 30px
}

.mt-31 {
  margin-top: 31px
}

.mt-32 {
  margin-top: 32px
}

.mt-33 {
  margin-top: 33px
}

.mt-34 {
  margin-top: 34px
}

.mt-35 {
  margin-top: 35px
}

.mt-36 {
  margin-top: 36px
}

.mt-37 {
  margin-top: 37px
}

.mt-38 {
  margin-top: 38px
}

.mt-39 {
  margin-top: 39px
}

.mt-40 {
  margin-top: 40px
}

.mb-8 {
  margin-bottom: 8px
}

.mb-9 {
  margin-bottom: 9px
}

.mb-10 {
  margin-bottom: 10px
}

.mb-11 {
  margin-bottom: 11px
}

.mb-12 {
  margin-bottom: 12px
}

.mb-13 {
  margin-bottom: 13px
}

.mb-14 {
  margin-bottom: 14px
}

.mb-15 {
  margin-bottom: 15px
}

.mb-16 {
  margin-bottom: 16px
}

.mb-17 {
  margin-bottom: 17px
}

.mb-18 {
  margin-bottom: 18px
}

.mb-19 {
  margin-bottom: 19px
}

.mb-20 {
  margin-bottom: 20px
}

.mb-21 {
  margin-bottom: 21px
}

.mb-22 {
  margin-bottom: 22px
}

.mb-23 {
  margin-bottom: 23px
}

.mb-24 {
  margin-bottom: 24px
}

.mb-25 {
  margin-bottom: 25px
}

.mb-26 {
  margin-bottom: 26px
}

.mb-27 {
  margin-bottom: 27px
}

.mb-28 {
  margin-bottom: 28px
}

.mb-29 {
  margin-bottom: 29px
}

.mb-30 {
  margin-bottom: 30px
}

.mb-31 {
  margin-bottom: 31px
}

.mb-32 {
  margin-bottom: 32px
}

.mb-33 {
  margin-bottom: 33px
}

.mb-34 {
  margin-bottom: 34px
}

.mb-35 {
  margin-bottom: 35px
}

.mb-36 {
  margin-bottom: 36px
}

.mb-37 {
  margin-bottom: 37px
}

.mb-38 {
  margin-bottom: 38px
}

.mb-39 {
  margin-bottom: 39px
}

.mb-40 {
  margin-bottom: 40px
}


/* selectbox */
.gap.selectbox-type01 {
  height: 50px;

  .selectbox select {
    background-color: #fff;
    border: 1px solid var(--yellow-20);
    border-radius: 4px;
    &.no-value{
      color:#8F8F8F;
    }

    &[disabled] {
      opacity: 1;
      border: 1px solid #ECEBE3;
      background-color: var(--yellow-20);
      color: var(--gray-40)
    }
  }
}

.default-all-select {
  .selectbox select,
  .selectbox select option[value=""] {
    color: var(--gray-100) !important;
  }
}

.height_100vh {
  height: calc(var(--vh, 1vh) * 100) !important;
  // overflow-x: hidden;
}
