
.picture-list-wrap{
  padding:0;
  margin:0;
  display:flex;
  flex-direction: row;
  list-style: none;
  gap: 10px;
  li{
    min-width:80px;
    min-height:80px;
  }
  .image-preview {
    width:80px;
    height:80px;
    background-size:cover;
    background-position:center;
    position: relative;
    border-radius: 40px;
    overflow: hidden;
    .overlay {
      will-change:opacity,visibility;
      visibility: hidden;
      opacity: 0;
      // transition: opacity 0.2s ease, visibility 0.2s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 24px;
      background-color: rgba(0, 0, 0, 0.5);
      .btn-delet{
        position:absolute;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        background: none;
        border: 0;
        z-index: 10;
      }
    }
    &:hover .overlay {
      visibility: visible;
      opacity: 1;
    }
    img{
      width:100%;
      height:100%;
      object-fit:cover
    }
  }
  .btn-fileUpload{
    position:relative;
    width:80px;
    height:80px;
    border-radius: 200px;
    background: var(--gray-100);
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icon_plus{
      position:relative;
      width:18px;
      height:18px;
      .line_y,
      .line_x {
        position: absolute;
        display: block;
        background: #fff;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .line_y{width:2px;height:18px;}
      .line_x{width:18px;height:2px;}
    }

    &.disabled{
      cursor:none;
      background: var(--yellow-20);
      .icon_plus{
        .line_y,.line_x{background:#A4A4A4;}
      }
    }
    input{
      cursor: pointer;
      position: absolute;
      top: -30px;
      left: -30px;
      width: calc(100% + 30px);
      height: calc(100% + 30px);
      opacity: 0;
      z-index: 3;
    }
  }
}
