
.wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100%;

  .isTop-0{
    .gnb-wrap{
      padding-left:304px;
    }
    .content-wrap{
      width: calc(100% - 232px);
    }
    .btn-menu-toggle{
      .icon-white{display:block}
      .icon-block{display:none}
    }

  }

  .btn-menu-toggle{
    .icon-white{display:none}
    .icon-block{display:block}
    position:fixed;
    top:15px;
    left:24px;
    z-index:1000;
    border:none;
    background:none;
    cursor: pointer;
  }

.gnb-wrap{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    display:flex;
    align-items: center;
    z-index:100;
    padding:0 24px 0 72px;
    background-color: #F8F7EF;
    height: 57px;
    border-bottom:1px solid var(--yellow-30);
    box-sizing: border-box;
    .r-menu-list{
      margin-left:auto;
      display:flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .menu-item{
        .link{
          &:hover{
            color: var(--Primary) !important;
          }
          &.bold{
            color: var(--gray-100);
            font-weight: 600;
          }
          align-items: center;
          cursor: pointer;
          color: var(--gray-90);
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.28px;
          background:none;
          border:none;
          text-decoration: none;
          display:flex;
          flex-wrap: nowrap;
        }
        & .link::before {
          content: "";
          display: inline-block;
          width: 1px;
          height: 8px;
          background-color: var(--yellow-30);
          margin: 0 20px;
        }
        &:first-child{
          .link::before {
            display:none;
          }
        }
        &:last-child{
          .link::before {
            display:none;
          }
          margin-left:20px;
        }

      }
    }
  }

.content-wrap{
  padding-top:57px;
  width:100%;
  // &.isLnb{
  //   width: calc(100% - 232px);
  //   @media (max-width: 1000px) {
  //     display: none
  //   }
  // }
  // height: calc(100% - 57px);

}

  .page-wrap {
    overflow: hidden;
    height: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    background-color: #F8F7EF;
    .header {
      display: flex;
      // justify-content: space-between;
      box-sizing: border-box;
      align-items: center;
      padding: 56px 72px 64px 72px;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 130px;
      .toggle-nav {
        // transform: translate(-36px, -52px);
        transform: translate(-72px, -100px);
      }
      .toggle-nav + .page-path-title {
        transform: translateX(-40px);
      }
      .page-path-title {
        // flex-basis: 100%;
        margin-right:10px;
        .full-path {
          color: #BDBDBD;
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
        }
        .title {
          color: var(--gray-100);
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -1.28px;
        }
      }
    }
    .main {
      padding:0 72px;
      // background-color: #fffffff1;
      // border-bottom: 1px solid #d0d0d0;
    }
  }

  .component-fade-enter-active,
  .component-fade-leave-active {
    transition:
      opacity .3s linear,
      filter  .3s linear,
    ;
  }
  .component-fade-enter,
  .component-fade-leave-to {
    opacity: .95;
    filter: blur(2px);
  }
  $offset: 187;
  $duration: 1.4s;
  .spinner-wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
  }
  .spinner {
    animation: rotator $duration linear infinite;
  }

  @keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
  }
  .path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
      dash $duration ease-in-out infinite,
      colors ($duration*4) ease-in-out infinite;
  }
  @keyframes colors {
    0% { stroke: #4285F4; }
    25% { stroke: #DE3E35; }
    50% { stroke: #F7C223; }
    75% { stroke: #1B9A59; }
    100% { stroke: #4285F4; }
  }
  @keyframes dash {
    0% { stroke-dashoffset: $offset; }
    50% {
      stroke-dashoffset: $offset/4;
      transform:rotate(135deg);
    }
    100% {
      stroke-dashoffset: $offset;
      transform:rotate(450deg);
    }
  }

  //td 안에 버튼이 들어간경우 td 높이가 늘어나느거 방지용 스타일
  /deep/ .customButton{
    position:relative;
    .gap{
      width: 50px; height: 32px;position:absolute;top:50%;right:0;margin-top:-16px;
    }
  }

  // /deep/ {
  //   .multiselect {
  //     display: inline-block;
  //     box-sizing: border-box;
  //     margin: 4px 0;
  //     padding:0  2.5px;
  //     line-height: 24px;
  //     min-width: 60px;
  //     min-height: 24px;
  //     vertical-align: middle;
  //     &--disabled {
  //       background: transparent;
  //       .multiselect__select {
  //         background: transparent;
  //       }
  //       .multiselect__single {
  //         background: #f6f6f6;
  //         color: #000;
  //          height: 24px;
  //         padding: 2px 0 0 5px;
  //       }
  //       .multiselect__tags {

  //         background: #f6f6f6;
  //       }
  //       .multiselect__placeholder {
  //         color: #000;
  //       }
  //     }
  //   }

  //   .multiselect__select {
  //     right: -6px;
  //     top: 2px;
  //     line-height: 24px;
  //     height: 24px;
  //     &:before {
  //       border-color: var(--theme-primary-color) transparent transparent;
  //       top: 7px;
  //       border-style: solid;
  //       border-width: 5px 5px 0;
  //     }
  //   }
  //   .multiselect__tag {
  //     background-color: var(--theme-primary-color);
  //     margin: 2.5px;
  //     vertical-align: top;
  //     height: 20px;
  //   }
  //   .multiselect__tag-icon {
  //     &:hover {
  //       background: var(--theme-primary-color);
  //     }
  //     &:after {
  //       color: #fff;
  //     }
  //   }
  //   .multiselect__tags {
  //     padding: 0;
  //     min-height: 22px;
  //     margin: 0;
  //     border-color: #ddd;
  //     box-sizing: border-box;
  //   }
  //   .multiselect--active {
  //     .multiselect__tags .multiselect__input {
  //       // margin-top: 5px;
  //     }
  //     .multiselect__tags-wrap {
  //       overflow: initial;
  //       height: 22px;
  //     }
  //   }
  //   .multiselect__tags-wrap {
  //     position: relative;
  //     // top: 2px;
  //     overflow: auto;
  //     height: fit-content;
  //     display: inline-block;
  //     width: calc(100% - 24px);
  //   }
  //   .multiselect__input {
  //     width: auto;
  //     font-size: 14px;
  //     line-height: 24px;
  //     text-indent: 0px;
  //     margin-bottom: 0;
  //   }
  //   // .multiselect__placeholder {
  //   //   font-size: 14px;
  //   //   line-height: 24px;
  //   //   padding: 0;
  //   //   margin: 0;
  //   //   text-indent: 5px;
  //   //   height: 24px !important;
  //   //   overflow: hidden;
  //   //   display: block !important;
  //   //   text-overflow: ellipsis;
  //   //   font-weight: normal;
  //   // }
  //   .multiselect__option {
  //     padding: 0 10px;
  //     height: 24px;
  //     min-height: 0;
  //     line-height: 24px;
  //     font-size: 14px;
  //   }
  //   .multiselect__option--highlight::after, .multiselect__option--selected::after {
  //     height: 24px;
  //     min-height: 0;
  //     line-height: 24px;
  //   }
  //   .multiselect__content-wrapper{
  //     min-width: 150px;
  //     width: calc(100% - 5px);
  //     padding: 0 2.5px;
  //     box-sizing: border-box;
  //     ul {
  //       padding-inline-start: 0;
  //       li {
  //         width: 100%;
  //         overflow: hidden;
  //       }
  //     }
  //   }
  //   .multiselect__option--highlight {
  //     background-color: var(--theme-primary-color);
  //     &::after {
  //       background-color: var(--theme-primary-color);
  //     }
  //   }
  //   .multiselect__single {
  //     font-weight: normal;
  //     font-size: 14px;
  //     margin: 1px 0;
  //     height: 22px;
  //     line-height: 22px;
  //     overflow: hidden;
  //     white-space:nowrap;
  //     text-overflow: ellipsis;
  //   }
  // }
  /deep/ .ck-editor {
    .ck.ck-labeled-field-view>.ck.ck-labeled-field-view__input-wrapper>.ck.ck-label {
      height: 14px;
    }
  }
  .version-notice{
    z-index: 10;
    position: fixed;
    background-color: #2cb6af;
    color: white;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding: 10px;
    animation: showfadeout 5s normal;
    opacity: 1;
    cursor: pointer;
  }
  @keyframes showfadeout {
    50% {
      transition: transform 3s;
      transform: translateY(100%);
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 600px) {

  .container {
    padding: 0 7.5px;
  }

  .wrap {
    .normal {
      // display: block;
      display: flex;
      min-height: 100%;
      align-content: initial;
      width: 100%;
    }
    .navigation + .page-wrap {
      display: none;
    }
    .page-wrap {
      overflow: initial;
      .header {
        // position: sticky;
        background: #F8F7EF;
        // top:0;
        z-index: 95;
        // z-index: 900;
        flex-basis: initial;
        padding: 30px 7.5px 6px 7.5px;
        .toggle-nav {
          margin : 0;
          transform: translate(0,0);
        }
        .toggle-nav + .page-path-title {
          transform: translateX(0);
        }
        .page-path-title {
          // flex-basis: 100%;
          margin-right:10px;
          overflow: hidden;
          .full-path {
            padding: 4px 5px;
            color: #BDBDBD;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .title {
            padding: 0 5px;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
          }
        }
        .action-bar {
          text-align: right;
        }
      }
      .main {
        padding:50px 20px 0 20px;
        background-color: #F8F7EF;
        // border-bottom: 1px solid #d0d0d0;
        /deep/ .filter label {
          display: block;
        }
        /deep/ .filter .inputbox>label {
          display: inline-block;
        }
      }
    }
  }

}

