
/deep/ .popup-wrap {
  &.confirm-box {
    .button-group {
      gap: 8px;

      .gap{
        border-radius: 100px;
        &:first-child{
          border: 1px solid var(--gray-100);
        }
        // &:last-child{
        //   border: 1px solid var(--gray-100);
        // }
      }

      .btn-cancel {
        color: var(--gray-100);
        background-color: #F8F7EF !important;
      }
      .primary {
        color: #fff;
        border: none;
      }
      button{
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.2px;
      }
    }
  }

  .popup-container {
    max-width: 380px;
    padding: 30px;

    .popup-title {
      padding-top: 16px;
      height: auto;
      text-align: center;
      border-bottom: 0;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.3px;
    }

    .popup-content {
      padding: 0;

      .description {
        padding-top: 8px;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.3px;
        word-wrap: break-word;
      }

      .button-group {
        margin-top: 30px;
        width: 100%;

        .gap {
          width: 100%;
          height: 54px;

          & > button {
            border:none;
            font-size: 16px;
            border-radius: 27px;
            background: var(--gray-100);
          }
        }
      }
    }

    .btn-close {
      display: none;
    }
  }
}
