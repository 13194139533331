
/deep/{
  .sp-select-wrap{
    .disegn01{

        .multiselect__tags{
          background:#F1EDE1;
          border:0;
          min-height: 32px;
          padding-top: 3px;

        }
        .multiselect__placeholder,.multiselect__input{
          background:#F1EDE1;
          color: var(--gray-100);
          line-height: 16px;
          margin-top:2px;
        }
        .multiselect__placeholder {
          margin-bottom: 7px;
        }
        .multiselect__single{
          background:#F1EDE1;
          color: var(--gray-100);
          line-height: 24px;
        }
        .multiselect__select{
          top:7px;
        }
    }
  }
}

.body1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
$body-font-size: 16;
$hex-black: 0, 0, 0;
$theme-black: #000000;
$theme-white: #ffffff;
$theme-gray-3: #f7f7f7;
$theme-gray-5: #f2f2f2;
$theme-gray-10: #e5e5e5;
$theme-gray-20: #cccccc;
$theme-gray-50: #808080;
$theme-gray-60: #666666;
$theme-gray-70: #4c4c4c;
$theme-gray-80: #333333;

$theme-primary-red: #e94d4d;
$theme-primary-red-dark-50: #742727;
$theme-primary-red-dark-30: #a33636;
$theme-primary-red-light-50: #f4a6a6;
$theme-primary-red-light-80: #fbdbdb;
$theme-primary-red-light-90: #fdeded;
$theme-primary-red-light-95: #fef6f6;

$theme-primary-green: #47c944;
$theme-primary-green-dark-50: #236422;
$theme-primary-green-dark-30: #328d30;
$theme-primary-green-light-50: #a3e4a1;
$theme-primary-green-light-80: #daf4da;
$theme-primary-green-light-90: #edfaec;

$theme-primary-blue: #4d74ec;
$theme-primary-blue-dark-50: #273a76;
$theme-primary-blue-dark-30: #3651a5;
$theme-primary-blue-light-50: #a6baf6;
$theme-primary-blue-light-80: #dbe3fb;
$theme-primary-blue-light-90: #edf1fd;
$theme-primary-blue-light-95: #f6f8fe;

$theme-primary-yellow: #ffd600;
$theme-primary-yellow-dark-50: #806b00;
$theme-primary-yellow-dark-30: #b39600;
$theme-primary-yellow-light-50: #ffeb80;
$theme-primary-yellow-light-80: #fff7cc;
$theme-primary-yellow-light-90: #fffbe5;

$theme-dimmed: rgba(0, 0, 0, 0.5);
$theme-disabled: $theme-gray-20;

.sp-select {
  display: flex;
  flex-direction: column;
  &.flex-row {
    flex-direction: row;
    align-items: center;
    .label {
      flex: 0 0 auto;
      margin: 0 16px 0 0;
    }
  }
  .label {
    @extend .body1;
    color: $theme-gray-50;
    margin: 0 0 8px 0;
  }
  .sp-select-wrap {
    position: relative;
    width: 100%;
    min-height: 50px;
    // min-height: 32px;
    .multiselect {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .default-select {
    width: 100%;
    height: 36px;
    outline: none;
    pointer-events: none;
    opacity: 0;
  }
}

//##########################
//########select-box########
//##########################
$max-option-size: 200;

/deep/ .multiselect {
  min-height: 32px;
  &.sp-select-error {
    .multiselect__tags {
      border-color: $theme-primary-red;
    }
  }
  &.sp-select-single,
  &.sp-limiter {
    .multiselect__tags {
      padding-left: 16px;
    }
    .multiselect__select {
      right: 10px;
      // right: 16px;
    }
  }
  &.multiselect--disabled {
    background: transparent;
    opacity: 1;
    .multiselect__tags {
      background: $theme-gray-5;
      border-color: $theme-gray-5;
      .multiselect__tag {
        color: $theme-gray-50;
        background-color: transparent;
        border-color: $theme-gray-50;
      }
    }
    .multiselect__strong {
      color: $theme-gray-50;
      background-color: transparent;
      border-color: $theme-gray-50;
    }
    .multiselect__tag-icon {
      &:after {
        color: $theme-gray-50;
      }
    }
    .multiselect__select {
      background: transparent;
    }
    .multiselect__single {
      color: $theme-gray-50;
      background-color: transparent;
    }
  }
  .multiselect__placeholder,
  .multiselect__input {
    padding-left: 0;
    @extend .body1;
    // line-height: 34px;
    line-height: 30px;
    // margin-top:2px;
    &::placeholder {
      color: $theme-gray-20;
    }
  }
  .multiselect__input {
    margin-top: 2px;
    margin-bottom: 6px;
  }
  .multiselect__placeholder {
    @extend .body1;
    color: $theme-gray-20;
    // margin-bottom: 7px;
  }
  .multiselect__select {
    width: 16px;
    height: 16px;
    top: 17px;
    right: 8px;
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      top: 0;
      width: 16px;
      height: 16px;
      margin: 0;
      border: none;
      background-color: $theme-gray-50;
      -webkit-mask: url('@/assets/images/icon-select.svg') no-repeat 50% 50%;
      mask: url('@/assets/images/icon-select.svg') no-repeat 50% 50%;
    }
    .multiselect--disabled {
      .multiselect__single {
        color: $theme-gray-50;
      }
    }
  }

  .sp-select-option-size-70 .multiselect__tag span {
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 70px;
}

  .multiselect__tags {
    min-height: 50px;
    padding-left: 8px;
    padding-right: 32px;
    padding-top: 6px;
    border-color: $theme-gray-20;
    border-radius: 8px;
    .multiselect__tag {

            padding: 8px 26px 9px 10px;

      margin-right: 8px;
      margin-bottom: 0;
      border: 1px solid $theme-gray-10;
      border-radius: 4px;
      color: $theme-black;
      background: $theme-gray-3;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .multiselect__tag-icon {
    &:hover {
      background-color: transparent;
    }
    &:after {
      font-size: 22px;
      font-weight: 100;
      color: $theme-gray-50;
      line-height: 27px;
    }
  }
  .multiselect__strong {
    padding: 8px 8px 9px 8px;
    margin-left: 4px;
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    color: $theme-black;
    background-color: $theme-gray-3;
    border: 1px solid $theme-gray-10;
    border-radius: 4px;
  }
  .multiselect__single {
    overflow: hidden;
    @extend .body1;
    line-height: 34px;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 0;
    margin-bottom: 4px;
  }
  &.multiselect--active {
    .multiselect__tags {
      border-color: $theme-black;
      border-radius: 8px;
    }
  }
  .multiselect__content {
    padding: 8px 0;
  }
  .multiselect__option {
    @extend .body1;
    color: $theme-gray-50;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    &.multiselect__option--selected {
      padding-right: 42px;
      font-weight: 700;
      color: $theme-black;
      background: transparent url('@/assets/images/icon-check-black.svg') center right 16px no-repeat;
    }
    &.multiselect__option--highlight {
      color: $theme-black;
      background: transparent;
      &.multiselect__option--selected {
        padding-right: 42px;
        font-weight: 700;
        color: $theme-black;
        background: transparent url('@/assets/images/icon-check-black.svg') center right 16px no-repeat;
      }
      &:after {
        color: $theme-black;
        background: transparent;
      }
    }
  }
  .multiselect__content-wrapper {
    margin-top: 8px;
    color: $theme-gray-20;
    border-radius: 8px;
    box-shadow: 0 0 10px 10px var(--theme-black-opacity-3);
  }
}
@for $i from 1 through $max-option-size {
  /deep/ .sp-select-option-size-#{$i} {
    .multiselect__tag {
      span {
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: #{$i}px;
      }
    }
  }
}

.v-popper__inner {
  padding: 8px;
  @extend .body1;
  color: $theme-white;
  background-color: $theme-gray-80;
  border-radius: 8px;
}
//############################
//########//select-box########
//############################
