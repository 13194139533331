
svg.on path {
  stroke: var(--gray-100);
}

ion-icon {
  vertical-align: top;
}

nav {
  width: fit-content;
  margin: 48px auto 0 auto;
  font-size: 14px;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;

    /deep/ li {
      display: flex;
      align-items: center;
      margin: 0;

      &:nth-child(1) {
        margin-right: 20px;
      }

      &:nth-child(3) {
        margin: 0 27px;
        gap: 8px;

        input,
        .total {
          color: #000;
          font-size: 15px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.2px;
        }

        input {
          line-height: 30px;
          height: 30px;
          text-align: center;
          padding: 0;
          margin: 0;
          text-indent: 0;
          color: var(--gray-100);
        }
      }

      &:nth-child(5) {
        margin-left: 20px;
      }

      .button {
        // min-width: 43px;
        // color: #777;
        // // color: black;
        // cursor: pointer;
        // margin: 1px;
        // height:26px;
        // border-radius: 5px;
        // border:1px solid #ddd;
        // background-color: #fff;
        // line-height:14px;
        cursor: pointer;
        border: none;
        background: none;
        width: 20px;
        height: 20px;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.no-list {
  color: #000;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
}

// 페이지네이션 내부의 input-type = number 에 자동으로 생기는 상하 버튼 제거
/deep/ .wrap-input {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox  */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
