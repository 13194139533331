
.input-sizer {
  display: inline-grid !important;
  position: relative;
  height: fit-content;
  font-family: inherit;
  font-size: inherit;

  input {
    margin: 2px;
    padding: 2px 3px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 16px;
    font-family: "Pretendard", Pretendard;

    &:-webkit-autofill {
      -webkit-box-shadow: none;
      -webkit-text-fill-color: #FFFFFF;
    }

    &:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  &::after {
    content: attr(data-value) "     ";
    font-size: 14px;
    visibility: hidden;
    height: 1px;
    margin: 0 1px -1px 1px;
    font-family: NanumSquareAc;
    white-space: pre-wrap;
  }
}

input:focus {
  border-color: var(--theme-primary-color);
  animation: shadow 0.1s ease-in-out forwards;
}

@keyframes shadow {
  to {
    box-shadow: 0 0 6px var(--theme-primary-color);
  }
}

p {
  display: inline-block;
  margin: 0;
  padding: 0;
}
