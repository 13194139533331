
.gap {
  max-width: 100%;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 20px;
  box-sizing: border-box;
}
.wrap-input {
  position: relative;
  margin: 0 auto;
  width: calc(100% - 5px);
  box-sizing: border-box;
}
textarea {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  height: auto;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 4px 0;
  outline: none;
  resize: none;
  font-family: "Pretendard", Pretendard;
  &.plaintext {
    padding: 4px 0;
    border-color: transparent;
    &:disabled {
      background-color: transparent;
    }
  }
}
textarea:focus {
  border-color: var(--theme-primary-color);
  animation: shadow 0.1s ease-in-out forwards;
}
@keyframes shadow {
  to {
    box-shadow: 0 0 6px var(--theme-primary-color);
    /* box-shadow: 0 0 6px red; */
  }
}
.hide-caret {
  caret-color: transparent !important;
}
