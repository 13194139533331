
.infoBox {
  line-height:normal;
  color:var(--gray-100);
  font-size: 14px;
  font-weight: 400;
  .count {
    // color:#000;
    font-weight: 600;
  }
}
