
.navigation {
  padding: 105px 24px 100px 24px;
  height: 100%;
  overflow: auto;
  user-select: none;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 232px;
  box-sizing: border-box;
  background-color: #1e1e1e;
  color: #fff;

  .welcome-message {
    margin-bottom: 20px;
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -0.44px;
    line-height: normal;
    overflow: hidden;

    .ellipsis {
      display: flex;
      margin-bottom: 5px;
      span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 110px;
      }
    }
  }

  .custom-select{
    position:relative;
    display:flex;
    flex-direction: column;
    border-radius: 4px;
    background: var(--gray-80);
    &.show{
      border-radius:4px 4px 0px 0px ;
      .select-list{
        max-height: 240px; /* 펼쳐진 상태에서의 최대 높이 설정 (필요에 맞게 변경) */
      }
    }
    & + .custom-select{
      margin-top:10px;
    }
    .item{
        color: var(--gray-10);
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.2px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .selected{
      position:relative;
      padding:0 12px;
      display:flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      height: 49px;
      strong{
        color: var(--gray-20);
        font-size: 11px;
        font-weight: 400;
        line-height: normal;
        margin-bottom:4px;
      }
      pointer-events: none;
      &.icon{
        pointer-events: auto;
        &::before {
          content:'';
          position: absolute;
          top: 15px;
          right: 10px;
          display: block;
          width: 16px;
          height: 16px;
          margin: 0;
          border: none;
          background-color: #F0F0F0;
          -webkit-mask: url('@/assets/images/icon-menu-select.svg') no-repeat 50% 50%;
          mask: url('@/assets/images/icon-menu-select.svg') no-repeat 50% 50%;
          transition: transform 0.3s ease;
        }
      }
      &.active::before {
        transform: rotate(180deg); /* 180도 회전 */
      }
    }

    .select-list{
      position:absolute;
      top:47px;
      left:0;
      width:100%;
      z-index:30;
      background: var(--gray-80);
      max-height: 0; /* 처음에는 숨겨진 상태 */
      overflow-y: auto; /* 콘텐츠 넘침을 숨김 */
      transition: max-height 0.3s ease; /* 트랜지션 효과 */
      border-radius: 0px 0px 4px 4px;
      li{
        border-top: 1px solid var(--gray-70);
        padding:0 10px;
        height: 47px;
        line-height:47px;
        cursor: pointer;
        &:hover{
          background: var(--gray-70);
        }
      }

    }

  }

  .logo {
    font-style: normal;
    font-weight: 800;
    font-size: 0px;
    // line-height: 44.2px;
    width: 100%;
    margin-bottom: 40px;
    cursor: pointer;

    img {
      width: 155px;
      height: 29px;
    }
  }

  //logo

  .user-info {
    position: relative;
    padding: 20px 20px 0 20px;
    overflow: hidden;
    cursor: pointer;
    background: var(--gray-90);

    .user-info-icon {
      position: absolute;
      top: 32px;
      right: 20px;

      &.on {
        transform: rotate(180deg);
      }
    }

    .user-info-title {
      margin-bottom: 16px;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.36px;
      line-height: normal;
      overflow: hidden;

      .ellipsis {
        display: flex;
        margin-bottom: 5px;

        span {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 110px;
        }
      }
    }

    .user-info-sub {
      display: flex;
      flex-direction: row;
      // max-width: 130px;
      color: #e3e3e3;
      font-size: 13px;
      font-weight: 400;
      line-height: normal;
      padding-bottom: 24px;

      i {
        font-style: normal;
        width: 10px;
        text-align: center;
      }

      span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(50% - 5px);
      }
    }

    .hidden-menu {
      position: relative;
      cursor: default;

      > div + div {
        margin-top: 12px;
      }

      .label {
        display: block;
        color: #e3e3e3;
        font-size: 11px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px;
      }

      /deep/ .selectbox {
        select {
          border: 0;
          border-radius: 7px;
          background-color: var(--gray-70);
          color: var(--gray-10);
          font-size: 15px;
          font-weight: 500;
          letter-spacing: -0.2px;
          padding-right: 20px;
        }
      }

      .btn-box {
        margin-top: 24px;
        position: relative;
        left: -24px;
        border-top: 1px solid var(--gray-80);
        background: var(--gray-90);
        width: calc(100% + 48px);
        height: 44px;

        .gap {
          width: 100%;
          height: 100%;

          /deep/ .btn-logout {
            background: none;
            border: 0;
            color: #e3e3e3;
            font-size: 13px;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }

  //user-info

  .main-navigation {
    margin: 40px 0 0 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    > li {
      & + li {
        margin-top: 28px;
      }

      font-size: 18px;
      line-height: 20px;

      &.toggle {
        .icon-arrow {
          transform: rotate(90deg);
        }

        .sub-navigation {
          display: block;
        }

        .active {
          color: var(--theme-selected-color);
        }
      }

      .sub-navigation {
        display: none;
        padding: 16px 0 0 24px;

        > li {
          color: #e9e9e9;
          font-size: 15px;
          font-weight: 400;

          > a {
            display: inline-block;
            // color: #e9e9e9;
            color:#BCBCBC;
            // margin: 5px 0;
          }
        }

        > li + li {
          margin-top: 16px;
        }
      }

      > a {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.4px;
        color: #fff;
        display: inline-block;
        // margin: 40px 0 24px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
    }
  }

  //main-navigation

  @media (max-width: 1000px) {
    flex-basis: 100%;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }
}

.btn-guide-box{
  margin-top:40px;
  button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 20px 0 24px;
    width:100%;
    height:44px;
    border-radius: 7px;
    background: #353535;
    border:none;
    color: #E3E3E3;
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
  }
}
