
:root {
  --v-calendar-active-bg-color: var(--theme-primary-color);
  --v-calendar-datepicker-icon-color: var(--theme-primary-color);
  --v-calendar-range-bg-color: #edf0ff;
  --dynamic-width: 300px;
  --v-calendar-input-bg-disable-color: #f9f9f9;
}

.datepicker-wrap{
  text-align: left;
  position: relative;
  z-index: 2;
  container-name: datepicker;
  container-type: inline-size;
  // container-type: size;
  // position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: var(--dynamic-width);
  min-width: 300px;
  max-width: 100%;
  vertical-align: top;
  margin: 0;
}

  @media screen and (max-width: 1100px) {
  .v-calendar.f-datepicker .content {
    flex-direction: column !important;
    transform: translate(-10%, -17%) scale(73%) !important;
  }
}

.v-calendar.f-datepicker {
  display: inline-block;
  // margin: 4px 2.5px;
  margin-left:7px;
}
.v-calendar .number {
  margin: -2px 0 0 -5px;
}
.v-calendar .button{
  border-radius: 0;
  margin: 0;
  line-height: 10px;
  font-size: 20px !important;
}
.v-calendar.f-datepicker .content {
  flex-direction: row ;
  transform: translate(-11%, -20%) scale(73%);
}
.v-calendar.f-datepicker {
  display :inline-block;
  .input-field.long {
    min-width: 243px;
    input {
      color: #000;
      border-radius: 5px;
      height: 32px;
      border:none;
      padding: 1px 10px 1px 10px;
      box-sizing: border-box;
      background: #F1EDE1;
      cursor: pointer;
    }
    input:focus {
      border-color: var(--theme-primary-color);
      animation: shadow 0.1s ease-in-out forwards;
    }
    @keyframes shadow {
      to {
        box-shadow: 0 0 6px var(--theme-primary-color);
      }
    }
  }
  svg.datepicker{
    display:none;
  }
}
.filter-days {
  // display: inline-block;
  display:flex;
  vertical-align: top;
  > div + div {
    margin-left:6px;
  }
  //  margin: 4px 0;
  // margin: 0 4px;

  .button {
    padding:0 12px;
    border: 1px solid var(--gray-30);
    background:#F8F7EF;
    // margin: 0;
  }
}
