
.bg {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
  background: var(--gray-100);
  z-index: 101;
}
.popup-wrap {
  position: fixed !important;
  height: 100vh;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-container {
  position:relative;
  box-sizing: border-box;
  width:100%;
  z-index: 103;
  min-width: 100px;
  max-height: 100%;
  overflow-y: auto;
  // width: fit-content;
  height: fit-content;
  max-width: 440px;
  background: #F8F7EF;
  .btn-close{
    position: absolute;
    top: 16px;
    right: 20px;
    z-index: 2;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    font-size: 0;
    cursor: pointer;
  }
  .popup-title {
    position:relative;
    width:100%;
    height:56px;
    padding:0 20px;
    box-sizing: border-box;
    line-height:56px;
    color:var(--gray-100);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.3px;
    border-bottom: 1px solid var(--gray-20);
    span{
      white-space: pre-line;
    }
  }
  .popup-content{
    padding:30px 20px 40px 20px;
  }
  .description {
    font-size: 15px;
    color: #888899;
    white-space: pre-line;
    // padding-bottom: 10px;
  }
  .button-group {
    margin-top:40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
